<template>
  <div class="joinBox" v-show="isShowDialog">
    <div class="layer" @click="hide"></div>
    <div class="boxMain" :class="showForm ? '' : 'boxSecondMain'">
      <div class="boxContent">
        <form
          v-if="showForm"
          novalidate="true"
          ref="form"
          action
          @submit="checkForm"
          method="post"
        >
          <h3>{{ title }}</h3>
          <div class="formWrap">
            <div class="inputWrap inputRequireWrap">
              <input v-model="phone" type="tel" placeholder="手机号" />
              <p class="requireText" v-if="errors[0].show">
                {{ errors[0].value }}
              </p>
            </div>
            <div class="inputWrap inputRequireWrap">
              <input v-model="contact" type="text" placeholder="联系人" />
              <p class="requireText" v-if="errors[1].show">
                {{ errors[1].value }}
              </p>
            </div>
            <div class="inputWrap inputRequireWrap codeWrap">
              <input
                v-model="code"
                type="tel"
                maxlength="4"
                placeholder="验证码"
              />
              <button
                type="button"
                :disabled="disabled"
                class="codeBtn"
                @click="sendCode"
              >
                {{ buttonText }}
              </button>
              <p class="requireText" v-if="errors[2].show">
                {{ errors[2].value }}
              </p>
            </div>
            <div
              class="inputWrap"
              :class="{ inputRequireWrap: companyValid == true }"
            >
              <input v-model="company" type="text" :placeholder="companyText" />
              <p class="requireText" v-if="errors[3].show">
                {{ errors[3].value }}
              </p>
            </div>
            <div class="inputWrap">
              <input v-model="email" type="email" placeholder="邮箱(选填)" />
              <p class="requireText" v-if="errors[4].show">
                {{ errors[4].value }}
              </p>
            </div>
            <button type="submit" class="boxBtn">提交申请</button>
          </div>
        </form>
        <div v-else class="welcomebox">
          <div class="logoTitle">
            <img src="../../assets/images/logo.png" />
          </div>
          <div class="textbox">
            <p>感谢您的申请</p>
            <p>我们会尽快与您联系！</p>
            <p class="tel">官方热线电话：400-620-9299</p>
          </div>
          <div @click="hide" class="btnclose">关闭</div>
        </div>
      </div>
    </div>
    <div class="alertInfo" :style="{ display: displayStsates }">
      <div class="profPrompt_test">{{ aletMsg }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import api from '../../config/api.js'
export default {
  data () {
    return {
      time: 60, // 倒计时
      timer: null, // 定时器对象
      showForm: true, // 默认显示表单
      isShowDialog: false, // 默认关闭
      phone: '', // 手机号
      contact: '', // 联系人
      code: '', // 验证码
      company: '', // 企业名称
      email: '', // 提交申请
      disabled: false, // 禁用获取验证码按钮
      buttonText: '获取验证码', // 验证码按钮文字
      errors: [
        { value: '手机号码有误', show: false },
        { value: '请填写联系人', show: false },
        { value: '请填写验证码', show: false },
        { value: '请填写医疗机构', show: false },
        { value: '邮箱格式不对', show: false }
      ], // 验证提示
      aletMsg: '', // 弹出框中的提示语
      displayStsates: 'none'
    }
  },
  props: {
    companyValid: {
      // 默认企业名称不是必填
      type: Boolean,
      default: false
    },
    companyText: {
      // 默认企业名称placeholder
      type: String,
      default: '企业名称(选填)'
    },
    title: {
      type: String,
      default: '申请加盟官方代理'
    },
    type: {
      type: String,
      default: '0' // '0'代表申请加盟官方代理，'1'代表申请试用致医云诊室，'2'代表申请试用致医云HIS，'3'代表申请试用致医云药房
    }
  },
  methods: {
    hide () {
      this.isShowDialog = false
    },
    checkForm (e) {
      const that = this
      const code = that.trim(this.code)
      const contact = that.trim(that.contact)
      const email = that.trim(that.email)
      that.errors[1].show = contact === ''
      that.errors[2].show = that.validCode(code)
      if (email) {
        that.errors[4].show = email && that.validEmail(email)
      }
      if (that.companyValid) {
        const company = that.trim(that.company)
        that.errors[3].show = company === ''
      }
      that.checkPhone()
      // const startSubmit = that.errors.every(item => item.show === false) && !that.disabled
      const startSubmit = that.errors.every((item) => item.show === false)
      if (startSubmit) {
        const companyVal = that.company ? that.company : '""'
        const emailVal = that.email ? that.email : '""'
        const params =
          '{"link_name":' +
          that.contact +
          ',"link_phone":' +
          that.phone +
          ',"link_company":' +
          companyVal +
          ',"link_mail":' +
          emailVal +
          ',"code":' +
          that.code +
          ',"type":' +
          Number(that.type) +
          '}'
        const url = api.urlPrefix + api.commitApplyInfo + params
        // const url = '/applyInfo' + api.commitApplyInfo + params
        axios.get(url).then((res) => {
          if (res.data === '0000') {
            that.showForm = false
            // setTimeout(() => {
            //   location.reload()
            // }, 5000)
          } else {
            // alert(res.data)
            this.alertDia(res.data)
          }
        })
      }
      e.preventDefault()
    },
    checkPhone () {
      const phone = this.trim(this.phone)
      const isValidPhone = this.validPhone(phone)
      this.errors[0].show = isValidPhone
      return isValidPhone
    },
    sendCode () {
      const that = this
      if (that.checkPhone()) {
        return
      }
      const phone = { phone: parseInt(that.phone) }
      const url =
        api.urlPrefix + api.getVerificationCode + JSON.stringify(phone)
      // const url = '/applyInfo' + api.getVerificationCode + JSON.stringify(phone)
      axios.get(url).then((res) => {
        that.disabled = true
        that.buttonText = that.time + 's'
        that.countDown()
      })
    },
    countDown () {
      const that = this
      that.timer = setInterval(function () {
        if (that.time === 0) {
          clearTimeout(that.timer)
          that.disabled = false
          that.buttonText = '获取验证码'
        } else {
          that.time--
          that.buttonText = that.time + 's'
        }
      }, 1000)
    },
    validPhone (phone) {
      return !/^1[3456789]\d{9}$/.test(phone)
    },
    validEmail (email) {
      var re = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/
      return !re.test(email)
    },
    validCode (code) {
      var re = /^\d{4}$/
      return !re.test(code)
    },
    trim (str) {
      return str.replace(/^\s+|\s+$/g, '')
    },
    // 提示弹框
    alertDia (msg) {
      this.displayStsates = 'block'
      this.aletMsg = msg
      // 延迟2秒后消失 自己可以更改时间
      window.setTimeout(() => {
        this.displayStsates = 'none'
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.joinBox {
  .layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.2);
  }
  .boxMain {
    position: fixed;
    display: flex;
    align-items: center;
    top: 50%;
    left: 50%;
    z-index: 1100;
    background: #fff;
    width: 585px;
    height: 550px;
    margin-top: -275px;
    margin-left: -292px;
    border-radius: 0;
  }

  .boxContent {
    padding: 30px 58px;
    box-sizing: border-box;
    h3 {
      font-size: 30px;
      color: #333;
      font-weight: bold;
      padding-bottom: 30px;
    }
    .formWrap {
      width: 100%;
      .inputWrap {
        position: relative;
        z-index: 20;
        display: flex;
        margin-bottom: 20px;
        input {
          width: 100%;
          height: 48px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          border: 1px solid rgba(200, 200, 200, 1);
          font-size: 14px;
          padding: 14px 20px;
          box-sizing: border-box;
          color: #333;
        }
        input::-webkit-input-placeholder {
          color: #999;
        }
        .requireText {
          position: absolute;
          z-index: 21;
          top: 50px;
          left: 0;
          font-size: 12px;
          color: #d0021b;
        }
      }
      .inputRequireWrap:before {
        content: ' *';
        color: #d0021b;
        font-size: 30px;
        position: absolute;
        z-index: 21;
        left: -20px;
        top: 50%;
        margin-top: -10px;
      }
      .codeWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        input {
          margin-right: 20px;
        }
        .codeBtn {
          width: 182px;
          height: 48px;
          line-height: 48px;
          background: linear-gradient(
            90deg,
            rgba(92, 41, 246, 1) 0%,
            rgba(34, 19, 222, 1) 100%
          );
          border-radius: 4px;
          font-size: 16px;
          color: #fff;
          cursor: pointer;
          border: none;
          outline: none;
          &:disabled {
            background: gray;
            color: #ccc;
          }
        }
      }
      .boxBtn {
        width: 469px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        background: linear-gradient(
          90deg,
          rgba(92, 41, 246, 1) 0%,
          rgba(34, 19, 222, 1) 100%
        );
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        border: none;
        outline: none;
        margin-top: 30px;
      }
    }
  }
  .boxSecondMain.boxMain {
    top: 50%;
    left: 50%;
    z-index: 1100;
    margin-top: -165px;
    margin-left: -185px;
    width: 370px;
    height: 330px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    .boxContent {
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .welcomebox {
      .logoTitle {
        width: 100%;
        height: 60px;
        background: linear-gradient(
          90deg,
          rgba(98, 85, 255, 1) 0%,
          rgba(159, 124, 254, 1) 100%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 4px 0 0;
        img {
          width: 80px;
        }
      }
      .textbox {
        padding: 50px 0;
        p {
          font-size: 18px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 25px;
        }
        .tel {
          font-size: 14px;
          font-weight: normal;
          padding-top: 20px;
        }
      }
      .btnclose {
        width: 170px;
        height: 48px;
        background: linear-gradient(
          90deg,
          rgba(92, 41, 246, 1) 0%,
          rgba(34, 19, 222, 1) 100%
        );
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 48px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}
.alertInfo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.1);
  .profPrompt_test {
    padding: 10px 20px;
    overflow: hidden;
    line-height: 28px;
    border-radius: 4px;
    color: #000;
    position: absolute;
    background-color: #fbfbfb;
    top: 50%;
    left: 50%;
    font-size: 14px;
    font-family: Gotham-Book;
    opacity: 1;
    /* z-index: 1; */
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
</style>
